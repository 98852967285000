export default {
  defineSituacaoProcessoCor(situacaoCodigo) {
    switch (situacaoCodigo) {
      case "DIV":
        return "deep-purple";
      case "ABT":
        return "light-blue darken-3";
      case "AND":
        return "deep-orange";
      case "LNF":
        return "teal darken-3";
      case "LS":
        return "brown darken-1";
      case "LN":
        return "pink darken-1";
      case "REC":
        return "red darken-3";
      case "ENC":
        return "red darken-4";
      case "CCI":
        return "green darken-1";
      default:
        return "grey lighten-1";
    }
  }
};
