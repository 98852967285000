<template>
  <v-container fluid>
    <h2 class="text-center mb-3 titulo">
      Dashboard
    </h2>
    <v-divider class="mb-5" />
    <div v-if="isAvaliador()">
      <v-row justify="center">
        <v-col cols="auto">
          <v-card-text>
            <v-row justify="center">
              <v-col cols="auto" :key="botao.texto" v-for="botao in botoesAvaliador">
                <BotaoMenu :texto="botao.texto" :icone="botao.icone" :path="botao.path" :color="botao.color"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
    <div v-if="isAdministrador()">
      <v-row justify="center">
        <v-col cols="8">
          <GraficoInscricoes />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8">
          <CalendarioProcessos />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CalendarioProcessos from "@/components/pages/admin/dashboard/CalendarioProcessos";
import GraficoInscricoes from "@/components/pages/admin/dashboard/GraficoInscricoes";
import BotaoMenu from "@/components/base/BotaoMenu";
import {mapGetters} from "vuex";

export default {
  name: "Dashboard",
  components: { GraficoInscricoes, CalendarioProcessos, BotaoMenu },

  data() {
    return {
      botoesAvaliador: {
        lancarResultado: {icone: 'mdi-check-all', texto: 'Lançar Resultados', path: '/lancamento', color: 'info'},
        visualizarDocumentos: {icone: 'mdi-file-eye', texto: 'Visualizar Documentos', path: '/documentacao', color: 'accent'}
      }
    }
  },

  methods: {
    ...mapGetters("autenticacao", ["isAdministrador", "isAvaliador"]),
  }
};
</script>

<style scoped></style>
