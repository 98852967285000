<template>
  <v-card>
    <v-card-title class="justify-center">
      <h4>Calendário dos Processos</h4>
    </v-card-title>
    <v-card-text>
      <v-toolbar flat>
        <v-btn class="mr-4" color="grey darken-2" outlined @click="value = ''">
          Hoje
        </v-btn>
        <v-spacer />
        <v-toolbar-title v-if="$refs.calendar" class="mx-2">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn rounded small text @click="$refs.calendar.prev()">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn rounded small text @click="$refs.calendar.next()">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-progress-linear
          :active="loading"
          absolute
          bottom
          class="mb-1"
          color="accent"
          height="6"
          indeterminate
        ></v-progress-linear>
      </v-toolbar>
      <v-calendar
        ref="calendar"
        v-model="value"
        :events="events"
        color="accent"
        @change="getEvents"
      ></v-calendar>
    </v-card-text>
  </v-card>
</template>

<script>
import calendarioProcessoService from "@/services/calendarioProcesso.service";
import utils from "@/commons/utils";
import luxon from "@/core/luxon";

export default {
  name: "CalendarioProcessos",
  data() {
    return {
      value: "",
      events: [],
      loading: true
    };
  },

  methods: {
    dataConverter(data){
      return luxon.dataConverter(data).toFormat("yyyy-LL-dd");
    },

    async getEvents() {
      this.loading = true;

      await calendarioProcessoService
        .recuperarProcessosPublicados()
        .then(response => {
          const calendarios = response.data;

          this.events = calendarios.map(c => {
            return {
              name: c.processoNome + " - " + c.situacaoProcessoNome,
              start: this.dataConverter(c.inicio),
              end: this.dataConverter(c.fim),
              color: utils.defineSituacaoProcessoCor(c.situacaoProcessoCodigo)
            };
          });
        })
        .catch(() => {});

      this.loading = false;
    },

  }
};
</script>

<style scoped></style>
