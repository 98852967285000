<template>
  <v-card>
    <v-card-title class="justify-center">
      <h4>Inscrições Válidas</h4>
    </v-card-title>
    <v-card-subtitle>
      <div class="d-flex my-2 justify-end align-center ">
        Atualizado em {{ atualizadoEm }}
        <v-btn
          class="ml-3"
          small
          icon
          @click="recuperarQtdInscricoesPorProcessosAbertosEEmAndamento"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </div>
    </v-card-subtitle>
    <v-card-text>
      <apexchart
        ref="chart"
        type="bar"
        height="400"
        :options="grafico.chartOptions"
        :series="grafico.series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import inscricaoService from "@/services/inscricao.service";
import luxon from "@/core/luxon";

export default {
  name: "GraficoInscricoes",

  data() {
    return {
      atualizadoEm: "",
      grafico: {
        series: [
          {
            name: "Inscrições",
            data: []
          }
        ],
        chartOptions: {
          chart: {
            type: "bar",
            toolbar: {
              show: false
            }
          },
          tooltip: {
            enabled: false
          },
          noData: {
            text: "Sem inscrições no momento"
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ['']
            }
          },
          legend: {
            show: false
          },
          grid: {
            strokeDashArray: 2
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
              columnWidth: "50%",
              distributed: true
            }
          },

          xaxis: {
            labels: {
              style: {
                fontSize: "12px"
              }
            }
          },
          yaxis: {
            title: { text: "Quantidade de inscrições" }
          }
        }
      }
    };
  },

  async created() {
    await this.recuperarQtdInscricoesPorProcessosAbertosEEmAndamento();
  },

  methods: {
    async recuperarQtdInscricoesPorProcessosAbertosEEmAndamento() {
      await inscricaoService
        .recuperarQtdInscricoesPorProcessosAbertosEEmAndamento()
        .then(response => {
          let data = response.data;
          this.$refs.chart.updateSeries([
            {
              data: data.map(d => {
                return {
                  x: `${d.tipoProcessoCodigo} ${d.processoAno}-${d.processoPeriodo}`,
                  y: d.qtdInscricoes
                };
              })
            }
          ]);
          this.atualizadoEm = luxon.local().toFormat("d 'de' LLLL 'de' yyyy 'às' TT")
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped></style>
