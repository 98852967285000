<template>
  <v-btn x-large color="white"  :width="1.5*width" height="120" @click="redirecionar">
    <v-row justify="center">
      <v-col cols="12">
        <v-icon :color="color" x-large>{{ icone }}</v-icon>
      </v-col>
      <span :style="`font-size: ${ font } !important;`">{{ texto }}</span>
    </v-row>
  </v-btn>
</template>

<script>
export default {
  name: "BotaoMenu",

  props: {
    icone: {type: String, required: true},
    texto: {type: String, required: true},
    path: {type: String, required: true},
    color: {type: String, required: false, default: 'black'}
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 120
        case 'sm':
          return 120
        default:
          return 160
      }
    },

    font() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '8pt'
        case 'sm':
          return '8pt'
        default:
          return 'small'
      }
    }
  },

  methods: {
    redirecionar() {
      this.$router.push(this.path);
    }
  }
}
</script>

<style scoped>

</style>